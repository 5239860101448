<template>
  <div class="content">
    <base-header class="pb-7">
      <div class="row align-items-center py-4 d-md-none">
        <div class="col-lg-6 col-7">
          <h6 class="h2 text-white d-inline-block mb-0">汇总表</h6>
          <nav aria-label="breadcrumb" class="d-none d-md-inline-block ml-md-4">
            <route-bread-crumb></route-bread-crumb>
          </nav>
        </div>
      </div>
    </base-header>
    <div class="container-fluid mt--7">
      <card refs="l1" class="no-border-card" body-classes="pb-1" footer-classes="pb-2">
        <div class="row">
            <div class="col">
            <h3>{{ dataTypeName }}数据汇总</h3>
            </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <small><i class="el-icon-date"></i> 日期范围</small>
          </div>
          <div class="col d-md-flex">
            <div class="mr-md-1 my-1">
              <el-select v-model="filters.year" placeholder="年份" @change="yearChanged" style="width:90px">
                <el-option v-for="y in years" :key="y" :label="y" :value="y"></el-option>
              </el-select>
            </div>
            <div class="mx-md-1 my-1 d-flex">
              <el-date-picker v-model="filters.start" :picker-options="pickerOptions" type="date" class="mr-1" placeholder="起始日期"
                format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width:150px">
              </el-date-picker>
              <el-date-picker v-model="filters.end" :picker-options="pickerOptions" type="date" class="ml-1" placeholder="截止日期" 
                format="yyyy-MM-dd" value-format="yyyy-MM-dd" style="width:150px">
              </el-date-picker>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <small><i class="el-icon-office-building"></i> 企业信息</small>
          </div>
          <div class="col d-md-flex">
            <div class="mr-md-1 my-1">
              <el-input class="search" v-model="filters.company_name" placeholder="企业名称" clearable></el-input>
            </div>
            <div class="mx-md-1 my-1">
              <el-select v-model="filters.org_type" placeholder="企业性质" clearable @change="orgTypeChanged">
                <el-option v-for="item in orgs" :key="item.name" :label="item.name" :value="item.name">
                </el-option>
              </el-select>
            </div>
            <div class="mx-md-1 my-1">
              <el-select v-model="filters.org_district" placeholder="所属地区" style="width:120px" clearable>
                <el-option v-for="item in orgDistricts" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
            </div>
            <div class="mx-md-1 my-1">
              <el-select v-model="filters.org_name" placeholder="选择国企单位" v-if="filters.org_type === city_org">
                <el-option v-for="item in orgNames" :key="item" :label="item" :value="item">
                </el-option>
              </el-select>
              <el-input class="search" v-model="filters.org_name" placeholder="国企单位" clearable v-else></el-input>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <small><i class="el-icon-location-information"></i> 产品产地</small>
          </div>
          <div class="col d-md-flex">
            <div class="mr-md-1 my-1">
              <el-input class="search" v-model="filters.product_name" placeholder="产品名称" clearable></el-input>
            </div>
            <div class="mx-md-1 my-1">
              <el-input class="search" v-model="filters.province" placeholder="省份" style="width:100px" clearable></el-input>
            </div>
            <div class="mx-md-1 my-1">
              <el-input class="search" v-model="filters.place" placeholder="市、区、县" clearable></el-input>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12">
            <small><i class="el-icon-coin"></i> 金额范围</small>
          </div>
          <div class="col d-md-flex">
            <div class="mr-md-1 my-1">
              <el-input class="search" type="number" v-model="filters.min_amount" placeholder="最小金额" style="width:120px"></el-input>
              <span>~</span>
              <el-input class="search" type="number" v-model="filters.max_amount" placeholder="最大金额" style="width:120px"></el-input>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-12 d-md-flex justify-content-between">
            <div class="d-flex">
              <div class="mr-md-1 my-1">
                <el-button type="success" @click="() => { this.filters.page = 1; this.fetchData(false) }" :loading="loading" icon="el-icon-search" class="fw">{{ this.loading ? "加载中..." : "查询" }}</el-button>
              </div>
              <div class="ml-md-2 my-1">
                <el-button type="info" @click="openReport" class="fw" icon="el-icon-document" :disabled="loading">查看报表</el-button>
              </div>
            </div>
            <div>
              <el-button type="info" @click="fetchData(true)" class="fw" icon="el-icon-download" :disabled="loading">导出汇总表</el-button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <el-table refs="counterTable" row-key="id" v-loading="loading"
                header-row-class-name="thead-light" header-cell-class-name="text-center" style="width: 100%"
                :data="tableData" class="m2"
                :default-sort = "{prop: 'start_date', order: 'ascending'}" 
                @sort-change="sortChange" stripe>
                <el-table-column prop="company_name" label="企业名称" min-width="180" sortable="custom"></el-table-column>
                <el-table-column prop="org_type" label="企业性质" min-width="160" sortable="custom"></el-table-column>
                <el-table-column prop="product_name" label="产品名称" min-width="160" sortable="custom">
                </el-table-column>
                <el-table-column label="产地">
                    <el-table-column prop="province" label="省份" min-width="90"></el-table-column>
                    <el-table-column prop="place" label="市、区、县" min-width="120"></el-table-column>
                </el-table-column>
                <el-table-column prop="start_date" label="统计日期" min-width="160" sortable="custom">
                    <template slot-scope="{row}">
                        {{ toDateString(row.start_date) }} - {{ toDateString(row.end_date) }}
                    </template>
                </el-table-column>
                <el-table-column prop="amount" :label="dataTypeName+'金额(万元)'" min-width="100" sortable="custom">
                    <template slot-scope="{row}">
                        {{ row.amount.toFixed(2) }}
                    </template>
                </el-table-column>
                <el-table-column label="" width="40px" class-name="text-center">
                  <template slot-scope="{row}">
                    <a href="javascript:;" title="查看凭证附件" @click="openAttachments(row)"><i class="el-icon-paperclip"></i></a>
                  </template>
                </el-table-column>
            </el-table>
          </div>
        </div>
        <div slot="footer" class="col-12 px-0 d-flex justify-content-center justify-content-sm-between flex-wrap">
            <el-pagination class="pb-3 mx--3" background layout="sizes, prev, pager, next"
                :page-sizes="pageSizeOptions" :page-size="filters.limit"
                :total="total" :current-page="filters.page"
                @size-change="pageSizeChange" @current-change="pageIndexChange" @prev-click="movePrev" @next-click="moveNext">
            </el-pagination>
        </div>
      </card>
    </div>
    <el-drawer :visible="!!report" :append-to-body="true" :modal-append-to-body="true" :before-close="beforeCloseReport"
      direction="ltr" size="70%"
      :title="dataTypeName + '汇总报表 ' + getDateRange()">
        <div class="px-4">
          <data-report v-if="report" :filters="{ ...filters, ...sort }"></data-report>
        </div>
    </el-drawer>
    <el-drawer :visible="!!attachmentsRef" :append-to-body="true" :modal-append-to-body="true" :before-close="beforeCloseAttachment"
      direction="ltr" size="60%"
      title="查看附件">
        <div class="px-4">
          <attachments v-if="attachmentsRef" :data-input-id="attachmentsRef"></attachments>
        </div>
    </el-drawer>
  </div>
</template>
<script>
import RouteBreadCrumb from "@/components/Breadcrumb/RouteBreadcrumb";

export default {
  components: {
    RouteBreadCrumb,
    'data-report': () => import('./Report.vue'),
    'attachments': () => import('./Attachments.vue')
  },
  data() {
    return {
      loading: true,
      exporting: false,
      years: [],
      orgs: [],
      city_org: '市属国有企业',
      ditrict_org: '区属国有企业',
      pickerOptions: {
          disabledDate: (d) => {
              return d.getFullYear() != this.filters.year
          }
      },
      filters: { data_type: parseInt(this.$route.params.type), org_name: '', search: '', year: '', start: '', end: '', limit: 10, page: 1 },
      sort: { orderBy: 'start_date', order: 'asc' },
      tableData: [],
      pageSizeOptions: [10, 20, 50, 100],
      total: 0,
      report: null,
      attachmentsRef: null,
    }
  },
  watch: {
    $route(to, from) {
      if(to.name === from.name) {
        this.filters.data_type = this.$route.params.type
        this.fetchData()
      }
    }
  },
  computed: {
    dataTypeName () {
      switch (this.filters.data_type) {
        case '0': return '采购';
        case '1': return '销售';
        default: return ''
      }
    },
    orgNames() {
      const cityOrg = this.orgs.find(x => x.name === this.city_org)
      if (cityOrg) return cityOrg.companies
      return []
    },
    orgDistricts() {
      const districtOrg = this.orgs.find(x => x.name === this.ditrict_org)
      if (districtOrg) return districtOrg.districts
      return []
    }
  },
  methods: {
    yearChanged () {
      this.filters.start = null
      this.filters.end = null
    },
    getDateRange () {
      let name = ''
      name += this.filters.start || (this.filters.year + '-01-01')
      name += '~'
      name += this.filters.year === new Date().getFullYear() ? this.toDateString(new Date()) : this.filters.year + '-12-30'
      return name
    },
    sortChange ({prop, order}) {
      this.sort = { orderBy: prop, order: order === 'ascending' ? 'asc' : 'desc' }
      this.fetchData()
    },
    orgTypeChanged () {
      this.filters.org_name = null
    },
    fetchData(isExport) {
      if(!isExport) this.loading = true;
      else this.exporting = true;
      this.axios.get("datainputs/aggregate", { params: { ...this.filters, ...this.sort, type: isExport?'xlsx':'' }, responseType: isExport?'blob':'application/json' })
      .then((res) => {
        if(!isExport) {
            if (res.data.errcode == 0) {
                this.tableData = res.data.data.data
                this.total = res.data.data.total
                this.filters.page = res.data.data.page
                this.sum = res.data.sum;
            }
            else {
                this.$notifyErr('未能成功加载数据', res.data.errmsg);
            }
            this.loading = false;
        }
        else {
          var fileURL = window.URL.createObjectURL(new Blob([res.data]));
          var fileLink = document.createElement('a');

          fileLink.href = fileURL;
          fileLink.setAttribute('download', `${this.dataTypeName}汇总.${this.getDateRange()}.xlsx`);
          document.body.appendChild(fileLink);
          fileLink.click();
          this.exporting = false;
        }
      })
      .catch((e) => {
        this.$notifyErr('未能成功加载数据', e.message)
        this.loading = false
        this.exporting = false
      })
    },
    pageSizeChange(v) { this.filters.limit = v; this.fetchData() },
    pageIndexChange(v) { this.filters.page = v; this.fetchData() },
    movePrev() { this.filters.page --; this.fetchData() },
    moveNext() { this.filters.page ++; this.fetchData() },
    sum(params) {
        const { columns } = params
        const sums = []
        const $this = this
        columns.forEach((column, index) => {
          if($this.sum) {
            if (column.property === 'amount') {
              sums[index] = $this.sum
            }
            else {
                sums[index] = ''
            }
          }
          else {
            sums[index] = ''
          }
        })
        return sums
    },
    exportData() {
      this.fetchData(true)
    },
    openReport() {
      this.report = this.filters
    },
    beforeCloseReport() {
      this.report = null
    },
    openAttachments(row) {
      this.attachmentsRef = row.data_input_id
    },
    beforeCloseAttachment() {
      this.attachmentsRef = null
    }
  },
  mounted() {
    var curYear = new Date().getFullYear()
    for(var y = 2020; y <= curYear + 1; y++) {
        this.years.push(y)
    }
    this.filters.year = curYear
    this.filters.data_type = this.$route.params.type
    this.axios.get('orglib').then((res) => {
      this.orgs = res.data
      this.fetchData()
    })
  }
};
</script>
<style>
    .no-border-card .card-footer { border-top: 0; }
    .filters>div { margin-bottom:15px; }
    .filters .search.el-input { width: 230px; }
    .filters .el-select { width: 160px }
    .cell.action-col-header {
        display: block !important;
        text-align: center;
        padding-top: 9px !important;
    }
    .bg-amount {
        background-color: rgb(251, 252, 235) !important;
    }
    .el-table .cell { padding: 0; }
    .el-table__footer .cell { font-weight: bold; }
    div.el-table tbody td {
        padding: 0.5rem;
    }
    .filters .fw, .fw.el-input, .fw.el-select, .el-button.fw {
    width: 100% !important;
    }
    .card .table.m2 td, .card .table.m2 th, .card .el-table.m2 td, .card .el-table.m2 th {
      padding-left: 0.5rem !important;
      padding-right: 0.5rem !important;
    }
</style>